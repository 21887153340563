import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GrowerApi from "../../../core/api/growerAPI";
import type { ApplicationState } from "../../../core/redux/reducers";
import { setAnchorId } from "../../../core/redux/reducers/productTemplateSlice";
import {
  setNavigationMenu,
  setAppLinks,
} from "../../../core/redux/reducers/accountProfileSlice";
import locationIcon from "../../../assets/icons/edit_location.svg";
import GetNow from "./getNow";
import "./sidebar.less";

const SidebarMenu: React.FC = () => {
  const history = useNavigate();
  const locations = useLocation();

  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  useEffect(() => {
    if (ProfileDataSelector?.defaultLocation?.countryCode)
      GrowerApi.getNavigationMenuItems(
        ProfileDataSelector?.defaultLocation?.countryCode.toUpperCase()
      )
        .then((response: any) => {
          if (response.status === 200) {
            const { sidebarMenu, iosLink, androidLink } = response.data ?? {};
            const menus = sidebarMenu?.map(processMenu) ?? [];
            dispatch(setNavigationMenu(menus));
            dispatch(setAppLinks({ iosLink, androidLink }));
          }
        })
        .catch(() => {});
  }, [ProfileDataSelector?.defaultLocation]);
  useEffect(() => {
    ProfileDataSelector?.navigationMenu?.forEach((menu: any) => {
      if (menu.navigationUrl === window.location.pathname && !menu.select)
        selectMenuItemOnClick(window.location.pathname);
      else if (menu.submenus) {
        menu.submenus.forEach((submenu: any) => {
          if (
            submenu.navigationUrl === window.location.pathname &&
            !submenu.select
          )
            selectMenuItemOnClick(window.location.pathname);
        });
      }
    });
  }, [window.location.pathname, ProfileDataSelector?.navigationMenu]);
  const processMenu = (menu: any): any => {
    if (menu?.navigationUrl?.includes("internal")) {
      const url = menu.navigationUrl.replace("internal", "");
      return { ...menu, select: false, navigationUrl: url };
    }
    if (menu?.submenus) {
      const processedSubmenus = menu.submenus.map(processSubmenu);
      return { ...menu, submenus: processedSubmenus };
    }

    return { ...menu, select: false };
  };

  const processSubmenu = (sub: any): any => {
    if (sub?.navigationUrl?.includes("internal")) {
      const suburl = sub.navigationUrl.replace("internal", "");
      return { ...sub, select: true, navigationUrl: suburl };
    }
    return { ...sub, select: true };
  };
  const selectMenuItemOnClick = (path: string): void => {
    dispatch(setAnchorId(null));
    let changeMainMenu: any = [];
    if (ProfileDataSelector?.navigationMenu) {
      changeMainMenu = ProfileDataSelector?.navigationMenu.map((menu: any) => {
        if (menu?.navigationUrl === path) {
          return { ...menu, select: true };
        } else if (menu.submenus) {
          const changedSubmenu = menu.submenus.map((sub: any) => {
            if (sub.navigationUrl === path) return { ...sub, select: true };
            else return { ...sub, select: false };
          });
          return { ...menu, submenus: changedSubmenu };
        } else {
          return { ...menu, select: false };
        }
      });
      dispatch(setNavigationMenu(changeMainMenu));
      if (path.includes("https")) {
        window.open(path, "_blank");
      } else if (path.includes("dashboard#")) {
        if (locations.pathname === "/dashboard") window.location.replace(path);
        else
          history("/dashboard", {
            state: { anchorId: path },
          });
      } else {
        history(path);
      }
    }
  };
  const displaySubMenus = (menu: any): JSX.Element => {
    return (
      <>
        {menu?.submenus?.map((submenu: any) => (
          <div
            className={`side-submenu-item  ${
              submenu.select ? "side-menu-item-active" : ""
            }`}
            key={submenu?.name}
          >
            <button
              onClick={() => {
                selectMenuItemOnClick(submenu?.navigationUrl);
              }}
              className={`mf-btn-img side-menu-text sidebar_sub_menu_text_color mf-ml-25 mf-cursor-pointer ${
                submenu.select ? "side-menu-item-active" : ""
              }`}
            >
              {translate(submenu.name)}
            </button>
          </div>
        ))}
      </>
    );
  };

  const displayMenus = (menuItems: any): JSX.Element => {
    return (
      <>
        {menuItems?.map((menu: any, index: number) => {
          return (
            <div
              key={menu?.name}
              className={`side-submenu-item ${
                menu.select ? "sidebar-selected-background" : ""
              } `}
            >
              {menu.select && (
                <span
                  className={`side-menu-selected ${
                    index === 0 ? "mf-ml-0" : ""
                  }`}
                ></span>
              )}
              <div
                className={`side-submenu-item ${
                  menu?.icon ? "side-submenu-item-icon-left" : ""
                }`}
                key={menu.name}
              >
                {menu.icon && (
                  <img
                    src={menu.icon}
                    alt="icon"
                    className={`side-submenu-icon-size ${
                      menu.select ? "sidebar-selected-background" : ""
                    }`}
                  />
                )}
                <span className="sidebar_margin_left_5">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      menu?.navigationUrl &&
                        selectMenuItemOnClick(menu?.navigationUrl);
                    }}
                    className={`sidebar_button side-menu-text ${
                      menu?.navigationUrl
                        ? "mf-cursor-pointer"
                        : "mf-cursor-context-menu"
                    }  ${menu.select ? "side-menu-item-active" : ""}`}
                  >
                    {translate(menu?.name)}
                  </button>
                </span>
                {menu?.submenus && displaySubMenus(menu)}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="syt-antd-menu-item svg">
      <div className="side-menu-user">
        <span>
          <img src={locationIcon} alt="location" />
          {ProfileDataSelector?.userLocation
            ? ProfileDataSelector?.userLocation
            : "Location not found!"}
        </span>
      </div>
      <div className="get-now-menu-items">
        <GetNow />
      </div>
      {ProfileDataSelector?.navigationMenu && (
        <div className="side-menu-items">
          {displayMenus(ProfileDataSelector?.navigationMenu)}
        </div>
      )}
    </div>
  );
};

export default SidebarMenu;
