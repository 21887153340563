import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import LayoutWithMenu from "./LayoutWithMenu";
import LayoutWithoutMenu from "./LayoutWithoutMenu";

import { routes } from "../../../routes";
import { isAccessTokenAvailable } from "../../../core/utils/common-function";
import { type RoutesDto } from "../../../core/types/routes.dto";

export const AppLayout = (): JSX.Element => {
  const [routesWithoutMenu, setRoutesWithoutMenu] = useState<RoutesDto[]>([]);

  const getRoutesWithoutMenu = useCallback(() => {
    const openAndAuthRoutes = [...routes.protectedRoutes, ...routes.openRoutes];
    const routesHideMenu = openAndAuthRoutes.filter((item) => {
      return (
        item?.layoutWithoutSideMenu === true ||
        item?.layoutWithoutHeader === true ||
        item?.layoutWithoutFooter === true
      );
    });
    setRoutesWithoutMenu([...routesHideMenu]);
  }, []);

  useEffect(() => {
    getRoutesWithoutMenu();
  }, []);

  return (
    <>
      {isAccessTokenAvailable() ? (
        <LayoutWithMenu routesWithoutMenu={routesWithoutMenu}>
          <Outlet />
        </LayoutWithMenu>
      ) : (
        <LayoutWithoutMenu>
          <Outlet />
        </LayoutWithoutMenu>
      )}
    </>
  );
};

export default AppLayout;
