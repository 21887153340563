export const TECHINCAL_AGRONOMIC_REPORTS =
  "https://www.syngenta.pl/infopole-komunikat";
export const weatherCEHumUrl = (
  countrycode: string = "",
  languagecode: string = "",
  lat: number = 0,
  long: number = 0,
  securitykey: string = ""
): string => {
  return `https://visualization.cehub.syngenta-ais.com/forecastweatherdata?language=${languagecode}&country=${countrycode}&numberofforecast=5&Lat=${lat}&Long=${long}&UserSpraywdws=SW_Vixeranbiostim&CountrySpraywdws=sw_aerialspraygeneric%7Csw_groundsprayherbicide_ld%7Csw_groundsprayherbicide_sd&securitykey=${securitykey}`;
};
export const UAT_RECOMMENDATIONS = (languagecode: string = ""): string => {
  return `https://uat.seedsddg.cropwise.com?lang=${languagecode}`;
};
export const PROD_RECOMMENDATIONS = (languagecode: string = ""): string => {
  return `https://seedselector.syngenta.com?lang=${languagecode}`;
};
export const DEV_RECOMMENDATIONS = "https://dev.seedsddg.cropwise.com";
export const QA_RECOMMENDATIONS = "https://qa.seedsddg.cropwise.com";
export const UAT_RECOMMENDATIONS_DETAILS = "https://uat.seedsddg.cropwise.com";
export const PROD_RECOMMENDATIONS_DETAILS = "https://seedselector.syngenta.com";
export const METEOBLUE_WEATHER_URL_BASE = "https://www.meteoblue.com/";
export const METEOBLUE_WEATHER_URL_WIDGET_PATH = "/weather/maps/widget#coords=";
export const METEOBLUE_WEATHER_URL_CONFIG =
  "&map=precipitation~hourly-hourly~ICONAUTO~sfc~none";
export const PL_GROWTH_STAGE_CTA =
  "https://www.syngenta.pl/srodki-ochrony-roslin/regulatory-wzrostu/moddus-start";
export const HU_DISEASE_MODELS_CTA =
  "https://www.syngenta.hu/cropwise-protector?gclid=Cj0KCQjwh7K1BhCZARIsAKOrVqHelvD_UcbH_sJ_2dswMe1Fo5xd5wfjccWkrApI2MsWF9u_4MJrpakaAlZFEALw_wcB";
export const PRE_COMMITMENTS_CREATION =
  "https://syngenta--globaluat.sandbox.my.site.com/eVoucher/hu/?param=eyJjcm9wTmFtZSI6IkdyYWluIENvcm4iLCJDb3VudHJ5IjoiSFUifQ";
export const GB_DISEASE_MODELS_CTA =
  "https://www.syngenta.co.uk/crop-support/potatoes/blight";
export const GB_SYNGENTA_CRIP_SUPPORT_CTA =
  "https://www.syngenta.co.uk/crop-support";
export const LOYALTY_PORTAL_LINK =
  "https://www.syngenta.co.uk/loyalty/partnership-plan";
export const CONTACT_SYNGENTA_UK =
  "https://www.syngenta.co.uk/content/contact-us";
