import { type ButtonType } from "syngenta-digital-cropwise-react-ui-kit";

export enum BannerType {
  backgroundImage = "backgroundImage",
  backgroundColor = "backgroundColor",
}

export interface BannerDataDTO {
  bannerRefId: string;
  countryRefId: string;
  name: string;
  imageRefId: string;
  startDate: string;
  endDate: string;
  weight: number;
  isActive: boolean;
  isHero: boolean;
  businessUnit: string;
  bannerCategory: string;
  offerStatus: string;
  redirectUrl: string;
  updatedBy: string;
  createdBy: string;
  updatedOn: string;
  createdOn: string;
}

export interface BannersDTO {
  title: string;
  titleIcon?: string;
  buttonType: ButtonType;
  data: BannerDataDTO[];
  bannerType: BannerType;
}
