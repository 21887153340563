import { useEffect, type FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "syngenta-digital-cropwise-react-ui-kit";
import GrowerApi from "../../../core/api/growerAPI";
import { type ApplicationState } from "../../../core/redux/reducers";
import { setLanguageList } from "../../../core/redux/reducers/accountProfileSlice";
import { setCurrentLanguage } from "../../../core/redux/reducers/homePageSlice";
import i18n from "../../../Localization/i18";
import { type CustomMenuType } from "../../../core/types/accountProfile.dto";
import { changeLanguageBtnClick } from "../../../core/utils/analytics/constants";
import { sendAmplitudeData } from "../../../core/utils/analytics";

const CustomMenu: FC<CustomMenuType> = ({
  customLanguageLabel,
  className,
  dataTestId,
  mode,
  items,
  selectedKeys,
  onSelect,
  isUsingDefaultOnSelect,
  disabledOverflow,
  triggerSubMenuAction,
}: CustomMenuType) => {
  const dispatch = useDispatch();
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  useEffect(() => {
    !ProfileDataSelector?.languagesList &&
      GrowerApi.getLanguagesList()
        .then((res) => {
          if (res.status === 200) dispatch(setLanguageList(res.data));
        })
        .catch(() => {});
  }, []);

  const renderLanguageLabel = (): JSX.Element => {
    const displayLanguage = (): string => {
      let language = "";
      ProfileDataSelector?.languagesList?.forEach((list) => {
        if (list.languageCode === homePageState?.currentLanguage)
          language = list.name;
      });
      return language;
    };
    return (
      <div
        className="custom-language-menu-title sidebar"
        data-testid={`custom-language-menu-title-${homePageState?.currentLanguage}`}
      >
        {displayLanguage()}
      </div>
    );
  };

  const defaultFunctionForOnSelect = (selectedLanguage: {
    key: string;
  }): void => {
    dispatch(setCurrentLanguage(selectedLanguage.key));
    localStorage.setItem("userLanguage", selectedLanguage.key);
    i18n.changeLanguage(selectedLanguage.key).catch((_error) => {});
  };

  const handleLanguageSelection = (selectedLanguage: { key: string }): void => {
    if (onSelect) {
      onSelect(selectedLanguage);
      if (isUsingDefaultOnSelect) {
        defaultFunctionForOnSelect(selectedLanguage);
      }
    } else {
      defaultFunctionForOnSelect(selectedLanguage);
    }
  };

  const languageMenuItems = [
    {
      key: "language-submenu",
      popupClassName: "language-submenu",
      label: customLanguageLabel
        ? customLanguageLabel()
        : renderLanguageLabel(),
      children: ProfileDataSelector?.languagesList?.map((language: any) => {
        return {
          key: language?.languageCode,
          label: language?.name,
        };
      }),
    },
  ];

  return (
    <Menu
      onClick={() => {
        sendAmplitudeData(changeLanguageBtnClick);
      }}
      onSelect={handleLanguageSelection}
      selectedKeys={selectedKeys ?? [homePageState?.currentLanguage as string]}
      className={className ?? "language-selection-menu"}
      data-testid={dataTestId ?? "language-selection-menu"}
      items={items ?? languageMenuItems}
      mode={mode ?? "horizontal"}
      disabledOverflow={disabledOverflow ?? false}
      triggerSubMenuAction={triggerSubMenuAction ?? "click"}
    />
  );
};

export default CustomMenu;
