import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Input } from "antd";
import {
  Button,
  ButtonType,
  Progress,
  Tooltip,
  notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import type { ApplicationState } from "../../core/redux/reducers";
import { setPasswordRecoveryDetails } from "../../core/redux/reducers/accountProfileSlice";
import GrowerApi from "../../core/api/growerAPI";

import PasswordTooltip from "../../shared/components/passwordTooltip/passwordTooltip";
import AuthFooter from "../../shared/components/authFooter/AuthFooter";
import AuthWelcomeMessage from "../../shared/components/authWelcomeMessage/AuthWelcomeMessage";
import ConstantKey from "../../core/locale/strings.json";
import { PASSWORD_STRENGTH_INFO } from "../../core/constants/constant";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import "./changePassword.less";

const ChangePasswordPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthColor, setPasswordStrengthColor] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newParams: any = {
      growerRefId: searchParams.get("growerRefId") ?? "",
      token: searchParams.get("token") ?? "",
    };

    if (newParams.token && !ProfileDataSelector?.passwordRecoveryDetails) {
      dispatch(setPasswordRecoveryDetails(newParams));
    }
  }, [location]);
  const onPasswordFieldValueChange = (event: any): string => {
    const passwordValue = event.target.value;
    const hasUpperCase = /[A-Z]/.test(passwordValue);
    const hasNumber = /\d/.test(passwordValue);
    setNewPassword(passwordValue);
    if (passwordValue.length >= 8) {
      if (hasUpperCase && hasNumber) {
        setPasswordStrength(100);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.green);
        return translate(ConstantKey.STRONG);
      } else if (hasUpperCase || hasNumber) {
        setPasswordStrength(75);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.yellow);
        return translate(ConstantKey.FAIR);
      } else {
        setPasswordStrength(50);
        setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.red);

        return translate(ConstantKey.WEAK);
      }
    } else {
      if (passwordValue.length === 0) {
        setPasswordStrength(0);
      } else {
        setPasswordStrength(30);
      }

      setPasswordStrengthColor(PASSWORD_STRENGTH_INFO.grey);
      return translate(ConstantKey.TOO_SHORT);
    }
  };

  const onClickCancel = (): void => {
    navigator("/signin");
  };
  const onChangeOldPassword = (event: any): void => {
    setError(false);
    setOldPassword(event.target.value);
  };
  const onClickChangePassword = (): void => {
    if (oldPassword === newPassword) {
      setError(false);
      notification.error({
        message: translate(ConstantKey.ERROR_SAME_OLD_NEW_PASSWORD),
        description: "",
        placement: "topRight",
      });
    } else if (ProfileDataSelector?.passwordRecoveryDetails?.growerRefId) {
      setLoading(true);
      const payload = {
        growerRefId: ProfileDataSelector?.passwordRecoveryDetails?.growerRefId,
        newPassword,
        oldPassword,
      };
      if (ProfileDataSelector?.passwordRecoveryDetails?.token)
        GrowerApi.updatePassword(
          ProfileDataSelector?.passwordRecoveryDetails?.token,
          payload
        )
          .then((res: any) => {
            if (res.status === 200) {
              localStorage.clear();
              navigator("/new-password-success");
            }
          })
          .catch((error) => {
            if (error?.cause?.data?.status === 400) setError(true);
            else
              notification.error({
                message: error?.cause?.data?.errorMessage,
                description: "",
                placement: "topRight",
              });
          })
          .finally(() => {
            setLoading(false);
          });
    }
  };
  return (
    <div
      className="mf-auth-page-container"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/auth_bg_image.svg)`,
      }}
    >
      <div className="new-password-container">
        <div className="flex-grow-0">
          <img
            className="new-password-field-logo flex-grow-0"
            src={synMyFieldLogo}
            alt="Syngenta Myfield Logo"
          />
        </div>
        <div className="flex-grow-0">
          <p className="new-password-desc">
            {translate(ConstantKey.CHANGE_PASSWORD)}
          </p>
          <p className="new-password-msg">
            {translate(ConstantKey.CHANGE_PASSWORD_DES)}
          </p>
        </div>
        <div className="new-password-form flex-grow-0">
          <div className="mf-width-100 mf-pr-10 mf-pl-10">
            <p className="new-password-label">
              {translate(ConstantKey.OLD_PASSWORD)}
            </p>
            <Input.Password
              type="default"
              data-testid="change-password-input"
              placeholder={translate(ConstantKey.ENTER_YOUR_PASSWORD)}
              size="middle"
              className={`mf-accounts-input ${
                error && "change-passwerd-error"
              }`}
              value={oldPassword}
              onChange={onChangeOldPassword}
            />
            {error && (
              <p className="mf-field-error-msg">
                {translate(ConstantKey.INVALID_PASSWORD)}
              </p>
            )}
            <p className="new-password-label mf-pt-10">
              {translate(ConstantKey.NEW_PASSWORD)}
              {passwordStrengthColor?.color && (
                <Tooltip
                  data-testid="new-password-tooltip"
                  title={<PasswordTooltip />}
                  placement="right"
                >
                  <p style={{ color: passwordStrengthColor?.color }}>
                    {translate(passwordStrengthColor?.message)}
                  </p>
                </Tooltip>
              )}
            </p>
            <Input.Password
              type="default"
              placeholder={translate(ConstantKey.ENTER_NEW_PASSWORD)}
              size="middle"
              className={`mf-accounts-input ${newPassword && "mf-error-field"}`}
              value={newPassword}
              onChange={onPasswordFieldValueChange}
            />

            <Progress
              gapDegree={0}
              percent={passwordStrength}
              status="success"
              strokeColor={passwordStrengthColor?.color}
              strokeLinecap="round"
              strokeWidth={6}
              type="line"
              showInfo={false}
            />
          </div>
          <div className="new-password-buttons">
            <Button
              type={ButtonType.outline}
              key="loginBtn"
              className="change-password-btns"
              onClick={onClickCancel}
            >
              {translate(ConstantKey.CANCEL)}
            </Button>
            <Button
              loading={loading}
              type={ButtonType.primary}
              key="loginBtn"
              className="change-password-btns"
              onClick={onClickChangePassword}
              data-testid="change-password-button"
              disabled={!(oldPassword && newPassword)}
            >
              {translate(ConstantKey.CHANGE_PASSWORD)}
            </Button>
          </div>
        </div>
        <AuthFooter />
      </div>
      <AuthWelcomeMessage />
    </div>
  );
};

export default ChangePasswordPage;
