import i18n from "i18next";
import { LANGUAGES } from "./LanguageConstants";
import axios from "axios";

/**
 * Method to get the all availble language data
 */
const loadLangFromPhrase = async (): Promise<any> => {
  const host = process.env.REACT_APP_PHRASE_HOST;
  const projectId = process.env.REACT_APP_PHRASE_PROJECT_ID;
  const accessToken = process.env.REACT_APP_PHRASE_ACCESS_TOKEN;
  const queryParams = `access_token=${accessToken}&file_format=i18next`;
  const baseURL = `${host}/projects/${projectId}/locales`;
  // Pulling all locales array
  const locales = await axios.get(`${baseURL}?${queryParams}`);
  // Pulling all translations for respective locale
  const allLocaleArray = Object.values(locales);
  if (allLocaleArray.length) {
    const allLocaleDownloadRequest = allLocaleArray
      .filter((locale) => locale.name)
      .map(async (locale) => {
        const res = await axios.get(
          `${baseURL}/${locale.id}/download?${queryParams}`
        );
        return { data: res, name: locale.name };
      });
    return (await Promise.allSettled(allLocaleDownloadRequest)).map(
      (result: any) => result?.value
    );
  }
};

export const getAllTranslation = async (): Promise<any> => {
  const allTranslation = await loadLangFromPhrase();
  i18n.languages = [LANGUAGES.English, LANGUAGES.Polish, LANGUAGES.Hungarian];
  allTranslation.forEach((eachTranslation: any) => {
    i18n.addResourceBundle(
      eachTranslation.name,
      "translation",
      eachTranslation.data,
      true,
      true
    );
  });
  await i18n.reloadResources();
};
