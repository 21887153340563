import { sendAmplitudeData } from "../../../core/utils/analytics";
import {
  claimOffersClick,
  commitedOffersClick,
  mapTabClick,
  newOffersClick,
  recommendationClick,
  titleSyngnetaTechnicalReportsClick,
  viewHomeScreen,
} from "../../../core/utils/analytics/constants";

export const logSideBarMenuClickAmplitudeEvent: Record<string, () => void> = {
  dashboard: () => {
    sendAmplitudeData(viewHomeScreen);
  },
  newoffers: () => {
    sendAmplitudeData(newOffersClick);
  },
  committedoffers: () => {
    sendAmplitudeData(commitedOffersClick);
  },
  claimed: () => {
    sendAmplitudeData(claimOffersClick);
  },
  seedrecommendations: () => {
    sendAmplitudeData(recommendationClick);
  },
  mapview: () => {
    sendAmplitudeData(mapTabClick);
  },
  summaryinstights: () => {
    sendAmplitudeData(titleSyngnetaTechnicalReportsClick);
  },
};
