import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import {
  Button,
  ButtonType,
  Modal,
  notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { setRefreshUserData } from "../../core/redux/reducers/accountProfileSlice";

import ConstantKey from "../../core/locale/strings.json";
import {
  SignInReduceActionTypeDTO,
  type SignInReducerActionDTO,
  type FormState,
} from "../../core/types/authentication.dto";
import GrowerApi from "../../core/api/growerAPI";
import useForm, { isValidEmail } from "../../core/hook/useForm";
import { sendAmplitudeData } from "../../core/utils/analytics";
import {
  iForgotPasswordClick,
  userSigninSuccess,
} from "../../core/utils/analytics/constants";

import AuthFooter from "../../shared/components/authFooter/AuthFooter";
import AuthWelcomeMessage from "../../shared/components/authWelcomeMessage/AuthWelcomeMessage";

import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import personIcon from "../../assets/icons/person-icon.svg";
import reportProblemYellow from "../../assets/icons/report_problem_yellow.svg";
import fieldErrorIcon from "../../assets/icons/field-error.svg";

import "./signin.less";

interface GrowerInfo {
  email: string;
  isOnlyCropwiseUser: boolean;
}
export function loginReducer(
  state: FormState,
  action: SignInReducerActionDTO
): any {
  switch (action.type) {
    case "EMAIL":
      return { ...state, email: { ...state.email, value: action.payload } };
    case "PASSWORD":
      return {
        ...state,
        password: { ...state.password, value: action.payload },
      };
    default:
      throw new Error();
  }
}
export const initialFormState: FormState = {
  email: {
    value: "",
    required: true,
    requiredErrorMessage: ConstantKey.INVALID_EMAIL,
    validate: isValidEmail,
    validateMessage: ConstantKey.INVALID_EMAIL,
  },
  password: {
    value: "",
    required: true,
    requiredErrorMessage: ConstantKey.PASSWORD_REQUIRED,
  },
};

export const SignInComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();
  const { t: translate } = useTranslation();
  const location = useLocation();

  const [state, dispatch] = useReducer(loginReducer, initialFormState);

  const [step, setStep] = useState<"step1" | "step2">("step1");
  const [isUserNotAllowed, setIsUserNotAllowed] = useState<boolean>(false);
  const [isHaveContracts, setIsHaveContracts] = useState<boolean>(false);
  const [isCropwiseUser, setIsCropwiseUser] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [growerInfo, setGrowerInfo] = useState<GrowerInfo | null>();
  const { formErrors, handleChange } = useForm(initialFormState);

  const modalErroInfo = {
    closable: false,
    title: translate(ConstantKey.MYFIELD_CONTRACT_ERROR),
    open: isHaveContracts,
    content: (
      <a
        className="splash-signup-link"
        href="mailto:support@syngentadigital.co.uk"
      >
        <span className="mf-ml-5"> {translate(ConstantKey.SUPPORT_EMAIL)}</span>
      </a>
    ),
    onOk: () => {
      setIsHaveContracts(false);
      navigate("/splash");

      window.location.reload();
    },
  };

  useEffect(() => {
    if (location?.state?.email) {
      dispatch({
        type: SignInReduceActionTypeDTO.EMAIL,
        payload: location.state.email,
      });
    }
    const isAccountDeleted = localStorage.getItem("accountDeleted");
    if (isAccountDeleted) {
      notification.success({
        message: translate(ConstantKey.ACCOUNT_DELETED_SUCCESSFULLY),
        description: translate(ConstantKey.ACCOUNT_DELETED_SUCCESSFULLY_DESC),
        placement: "topRight",
      });
      localStorage.removeItem("accountDeleted");
    }
  }, [location.state]);

  const continueNextStop = useCallback(() => {
    const domain = state?.email?.value.split("@")[1];
    if (domain === "syngenta.com") {
      setIsUserNotAllowed(true);
      return;
    }
    setLoading(true);
    GrowerApi.checkGrowerInfo(state?.email?.value)
      .then((res) => {
        if (res.status === 200) {
          setGrowerInfo(res.data);
          setStep("step2");
        }
        if (res?.data?.isOnlyCropwiseUser) setIsCropwiseUser(true);
      })
      .catch((error) => {
        if (error?.cause?.status === 404) setIsOpen(true);
        else
          notification.error({
            message: `${translate(ConstantKey.SOMETHING_WENT_WRONG)}!`,
            description: "",
            placement: "topRight",
          });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [state]);

  const logForgotPasswordClick = useCallback(() => {
    const logDetials = {
      is_syngenta_email: state.email.value
        ?.trim()
        ?.toLowerCase()
        ?.includes("@syngenta"),
    };
    sendAmplitudeData(iForgotPasswordClick, logDetials);
  }, [state]);

  const loginUser = useCallback(() => {
    setLoading(true);
    const payload = {
      email: state.email.value,
      password: state.password.value,
      grant_type: "password",
      isOnlyCropwiseUser: growerInfo?.isOnlyCropwiseUser,
    };
    GrowerApi.growerSignIn(payload)
      .then((res) => {
        if (res?.data?.licensing_details?.apps?.length !== 0) {
          if (res) {
            localStorage.setItem(
              "oauth_api_response",
              JSON.stringify(res.data)
            );
            localStorage.setItem("access_tokens", res.data.access_token);
            localStorage.setItem("refresh_tokens", res.data.refresh_token);
            const logDetials = {
              is_syngenta_email: state.email.value
                ?.trim()
                ?.toLowerCase()
                ?.includes("@syngenta"),
              sign_in_at: new Date(),
            };
            sendAmplitudeData(userSigninSuccess, logDetials);
            dispatchRedux(setRefreshUserData(true));
          }
          setStep("step2");
        } else {
          setIsHaveContracts(true);
          Modal.error(modalErroInfo);
        }
      })
      .catch((error) => {
        const message =
          error?.cause?.data?.errorMessage ??
          translate(ConstantKey.SOMETHING_WENT_WRONG);
        notification.error({
          message,
          description: "",
          placement: "topRight",
        });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [state]);

  const redirectToSignUpFlow = useCallback(() => {
    setIsOpen(false);
    navigate("/signup-basic-info", {
      state: { email: { value: state.email.value } },
    });
  }, [state]);

  const emailOnChange = useCallback((event: any) => {
    handleChange("email", event.target.value);
    dispatch({
      type: SignInReduceActionTypeDTO.EMAIL,
      payload: event.target.value,
    });
  }, []);

  const passwordOnChange = useCallback((event: any) => {
    handleChange("password", event.target.value);
    dispatch({
      type: SignInReduceActionTypeDTO.PASSWORD,
      payload: event.target.value,
    });
  }, []);

  return (
    <>
      {createPortal(
        <Modal
          data-testid="user-not-found-modal"
          className="user-not-found-modal"
          cancelText={translate(ConstantKey.CANCEL)}
          title={
            <div className="new-error-modal-container">
              <img
                className="anticon"
                src={personIcon}
                alt="person Warning Icon"
              />
              <span className="error-modal-header-message">
                {translate(ConstantKey.YOU_DONT_HAVE_ACCOUNT)}
              </span>
            </div>
          }
          mask
          open={isOpen}
          okText={translate(ConstantKey.CREATE_NEW_ACCOUNT)}
          okType="primary"
          onCancel={() => {
            setIsOpen(false);
          }}
          onOk={redirectToSignUpFlow}
          closable={true}
          centered={true}
        >
          <p>{`${translate(ConstantKey.NO_ACCOUNT_FOUND)}: ${
            state?.email?.value
          }`}</p>
          <p>{translate(ConstantKey.NEED_TO_CREATE_ACCOUNT)}</p>
        </Modal>,
        document.body
      )}

      {createPortal(
        <Modal
          data-testid="user-not-allowed-modal"
          className="user-not-found-modal"
          title={
            <div className="new-error-modal-container">
              <img
                className="anticon"
                src={reportProblemYellow}
                alt="person Warning Icon"
              />
              <span className="error-modal-header-message">
                {translate(ConstantKey.USER_NOT_ALLOWED)}
              </span>
            </div>
          }
          mask
          open={isUserNotAllowed}
          onCancel={() => {
            setIsUserNotAllowed(false);
          }}
          footer={[
            <Button
              type={ButtonType.primary}
              key="okayBtn"
              onClick={() => {
                setIsUserNotAllowed(false);
              }}
              data-testid="okayBtn"
            >
              {translate(ConstantKey.OK)}
            </Button>,
          ]}
          closable={true}
          centered={true}
        >
          <p>
            {translate(ConstantKey.SYNGENTA_USER_NOT_ALLOWED)}{" "}
            <b>({state?.email?.value})</b>{" "}
            {translate(ConstantKey.IS_NOT_ALLOWED)}{" "}
            {translate(ConstantKey.CONTACT_FOR_SUPPORT)}{" "}
            <a
              className="splash-signup-link"
              href="mailto:support@syngentadigital.co.uk"
            >
              {translate(ConstantKey.SUPPORT_EMAIL)}
            </a>
          </p>
        </Modal>,
        document.body
      )}
      {createPortal(
        <Modal
          data-testid="sigin-cropwise-user"
          className="sigin-cropwise-user"
          title={
            <div className="new-error-modal-container">
              <span className="error-modal-header-message">
                {translate(ConstantKey.CROPWISE_USER)}
              </span>
            </div>
          }
          mask
          open={isCropwiseUser}
          onCancel={() => {
            setIsCropwiseUser(false);
          }}
          footer={[
            <Button
              type={ButtonType.primary}
              key="okayBtn"
              onClick={() => {
                setIsCropwiseUser(false);
              }}
              data-testid="okayBtn"
            >
              {translate(ConstantKey.OK)}
            </Button>,
          ]}
          closable={true}
          centered={true}
        >
          <p>{translate(ConstantKey.CROPWISE_USER_MSG)}</p>
        </Modal>,
        document.body
      )}
      <div
        className="mf-auth-page-container"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/auth_bg_image.svg)`,
        }}
      >
        <div className="login-container">
          <div className="flex-grow-0">
            <img
              className="syn-field-logo flex-grow-0"
              src={synMyFieldLogo}
              alt="Syngenta Myfield Logo"
            />
          </div>
          <div className="flex-grow-0">
            <p className="sign-in-sub-desc mf-mb-0">
              {translate(ConstantKey.SIGN_IN_SUB_TITLE_1)}
            </p>
            <p className="sign-in-sub-desc">
              {translate(ConstantKey.SIGN_IN_SUB_TITLE_2)}
            </p>
          </div>
          <div className="login-form flex-grow-0">
            <div className="mf-width-100 mf-pr-10 mf-pl-10">
              <p className="select-label mf-pt-10">
                {translate(ConstantKey.YOUR_EMAIL)}
              </p>
              <Input
                type="default"
                status={formErrors?.email ? "error" : ""}
                placeholder={translate(ConstantKey.ENTER_YOUR_EMAIL)}
                size="middle"
                data-testid="sign-in-email"
                className={`mf-accounts-input ${
                  formErrors?.email && "mf-error-field"
                }`}
                value={state.email.value}
                onChange={emailOnChange}
                disabled={step === "step2"}
                suffix={
                  formErrors?.email ? (
                    <img src={fieldErrorIcon} alt="SignUp info tooltip" />
                  ) : (
                    <></>
                  )
                }
              />
              {formErrors?.email && (
                <p
                  className="mf-field-error-msg"
                  data-testid="login-form-error-email"
                >
                  {formErrors?.email}
                </p>
              )}
            </div>
            {step === "step2" && (
              <>
                <div className="mf-width-100 mf-pr-10 mf-pl-10">
                  <p className="select-label mf-pt-10">
                    {translate(ConstantKey.PASSWORD)}
                  </p>
                  <Input.Password
                    type="default"
                    status={formErrors?.password ? "error" : ""}
                    placeholder={translate(ConstantKey.ENTER_YOUR_PASSWORD)}
                    size="middle"
                    className={`mf-accounts-input ${
                      formErrors?.password && "mf-error-field"
                    }`}
                    data-testid="sign-in-password"
                    value={state.password.value}
                    onChange={passwordOnChange}
                  />
                  <div
                    className={`${
                      formErrors?.password ? "sigin-flex-error-forgot" : ""
                    }`}
                  >
                    {formErrors?.password && (
                      <p
                        className="mf-field-error-msg"
                        data-testid="login-form-error-password"
                      >
                        {formErrors?.password}
                      </p>
                    )}
                    <Link
                      to="/forgot-password"
                      data-testid="forgot-password-id"
                      className="forgot-password"
                      onClick={logForgotPasswordClick}
                    >
                      {translate(ConstantKey.FORGOT_MY_PASSWORD)}
                    </Link>
                  </div>
                </div>
                <Button
                  loading={loading}
                  type={ButtonType.info}
                  data-testid="sign-in-btn"
                  className={`login-btn ${
                    !(state.email.value && state.password.value)
                      ? "login-btn-disabled"
                      : ""
                  }`}
                  key="loginBtn"
                  onClick={loginUser}
                  disabled={
                    !(
                      state.email.value &&
                      !formErrors?.email &&
                      state.password.value &&
                      !formErrors?.password
                    ) || loading
                  }
                >
                  {translate(ConstantKey.SIGN_IN)}
                </Button>
              </>
            )}
            {step === "step1" && (
              <Button
                loading={loading}
                type={ButtonType.info}
                data-testid="sign-in-continue-btn"
                className={`login-btn ${
                  !state.email.value ? "login-btn-disabled" : ""
                }`}
                key="loginBtn"
                onClick={continueNextStop}
                disabled={loading || !(state.email.value && !formErrors?.email)}
              >
                {translate(ConstantKey.CONTINUE)}
              </Button>
            )}
          </div>
          <AuthFooter />
        </div>
        <AuthWelcomeMessage />
      </div>
    </>
  );
};

export default SignInComponent;
