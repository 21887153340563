import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import ConstantKey from "../../../core/locale/strings.json";

import synMyFieldLogo from "../../../assets/images/synMyFieldLogo.svg";
import synDigitalLogo from "../../../assets/images/synDigitalLogo.svg";

import "./landingScreen.less";

interface LandingScreenDTO {
  children: ReactNode;
}

export const LandingScreen = ({ children }: LandingScreenDTO): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <div className="landing-screen-container">
      <div className="landing-screen-content">
        <img
          className="syn-field-logo"
          src={synMyFieldLogo}
          alt="Syngenta Myfield Logo"
        />
        {children}
      </div>
      <div className="syn-field-support">
        {translate(ConstantKey.NEED_HELP)}{" "}
        <a
          className="splash-signup-link"
          href="mailto:support@syngentadigital.co.uk"
        >
          {translate(ConstantKey.SUPPORT_EMAIL)}
        </a>
        <br />
        <img
          className="syn-support-logo"
          src={synDigitalLogo}
          alt="Syngenta Digital Logo"
        />
      </div>
    </div>
  );
};

export default LandingScreen;
