export const languageOptions: Array<{ label: string; value: string }> = [
  { label: "English", value: "en" },
  { label: "Polish", value: "pl" },
  { label: "Hungarian", value: "hu" },
  { label: "Romanian", value: "ro" },
];

export enum LANGUAGES {
  English = "en",
  Polish = "pl",
  Hungarian = "hu",
  Romanian = "ro",
}
