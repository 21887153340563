import { createSlice } from "@reduxjs/toolkit";

export interface HomePageInitialState {
  syngentaProductsDetails: any;
  currentLanguage: string;
  countryFromParams: string | null;
  tabFromParams: string | null;
}
const initialState: HomePageInitialState = {
  syngentaProductsDetails: null,
  currentLanguage: "en",
  countryFromParams: null,
  tabFromParams: null,
};
export const homePageSlice = createSlice({
  name: "home page data",
  initialState,
  reducers: {
    setSyngentaProductsDetails: (state, action) => {
      return {
        ...state,
        syngentaProductsDetails: action.payload,
      };
    },
    setCurrentLanguage: (state, action) => {
      return {
        ...state,
        currentLanguage: action.payload,
      };
    },
    setFromPars: (state, action) => {
      return {
        ...state,
        countryFromParams: action.payload.countryFromParams,
        tabFromParams: action.payload.tabFromParams,
      };
    },
  },
});
export const { setSyngentaProductsDetails, setCurrentLanguage, setFromPars } =
  homePageSlice.actions;
export default homePageSlice.reducer;
