import { type PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import {
  AntdConfigProvider,
  Layout,
} from "syngenta-digital-cropwise-react-ui-kit";

import { type ProfileInitialState } from "../../../core/types/accountProfile.dto";

import AppLoader from "../../components/AppLoader/AppLoader";

export const LayoutWithoutMenu = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const userProfileDetails: ProfileInitialState = useSelector(
    (state: any) => state.accountDetials
  );
  if (userProfileDetails.loading) return <AppLoader />;
  return (
    <AntdConfigProvider>
      <Layout className="mf-unauth-layout">{children}</Layout>
    </AntdConfigProvider>
  );
};

export default LayoutWithoutMenu;
