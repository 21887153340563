import axios, { type AxiosError } from "axios";
import { sendAmplitudeData } from "../utils/analytics";
import { apiServiceFailure } from "../utils/analytics/constants";
import { logError } from "../utils/common-function";

export const getAuthorizationHeaders = {
  "Content-Type": "application/x-www-form-urlencoded",
  authorization:
    "Basic " +
    btoa(
      `${process.env.REACT_APP_OAUTH_CLIENT_ID ?? ""}:${
        process.env.REACT_APP_OAUTH_SECRET ?? ""
      }`
    ),
};

export const baseURL = process.env.REACT_APP_BASE_API_URL;

export const axiosRequest = axios.create({
  baseURL,
});

const requests = {
  get: async (url: string) => await axios.get(url),
  post: async (url: string, body: any = null) => await axios.post(url, body),
  put: async (url: string, body: any) => await axios.put(url, body),
  del: async (url: string, body: any) => await axios.delete(url, body),
};

// request interceptors
axios.interceptors.request.use(
  (config) => {
    const jwt = localStorage.getItem("access_tokens");
    const isInternalAPI = config?.url?.includes("api.myfield.syngenta.com");
    if (isInternalAPI) {
      config.headers.Authorization = `Bearer ${jwt}`;
    }
    return config;
  },
  async (error: AxiosError) =>
    await Promise.reject(
      error?.message
        ? new Error(error?.message)
        : new Error("Something went wrong")
    )
);

// response interceptors
axios.interceptors.response.use(
  (response: any) => {
    return {
      ...response.data,
      status: response.status,
    };
  },
  async (error: any) => {
    const endpointUrl = error.config.url;
    const apiCallMethod = error.config.method;

    const amplitudeDetails = {
      status_code: error?.status,
      message: logError(error, endpointUrl, apiCallMethod),
      api_service_failure_at: new Date().getTime(),
    };
    sendAmplitudeData(apiServiceFailure, amplitudeDetails);
    return await Promise.reject(
      new Error("Something went wrong", { cause: error.response })
    );
  }
);

export default requests;
