import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import { sendAmplitudeData } from "../../core/utils/analytics";
import { exploreWeatherMapClick } from "../../core/utils/analytics/constants";
import { setAnchorId } from "../../core/redux/reducers/productTemplateSlice";
import ConstantKey from "../../core/locale/strings.json";
import insightsImage from "../../assets/images/insights_image.svg";
import "./dashboard.less";
const InsightsWidget = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickButton = (): void => {
    dispatch(setAnchorId(null));
    navigate("/mapview");
    sendAmplitudeData(exploreWeatherMapClick);
  };
  return (
    <div className="insights-container">
      <img className="insights-image" src={insightsImage} alt="insights" />
      <div className="insights-grouping">
        <div className="insights-title">
          {translate(ConstantKey.INSIGHTS_FIELD_FARM)}
        </div>
        <div className="insights-sub-title">
          {translate(ConstantKey.INSIGHTS_FIELD_FARM_DES)}
        </div>
        <Button
          onClick={onClickButton}
          className="insights-button"
          type={ButtonType.primary}
        >
          {translate(ConstantKey.GET_INSIGHTS_YOUR_FIELD_FARM)}
        </Button>
      </div>
    </div>
  );
};
export default InsightsWidget;
