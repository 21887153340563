import { useSelector } from "react-redux";
import { type ApplicationState } from "../../../core/redux/reducers";
import arrowIcon from "../../../assets/icons/Iconarrow.svg";

export const RenderLanguageLabel = (): JSX.Element => {
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const displayLanguage = (): string => {
    let language = "";
    ProfileDataSelector?.languagesList?.forEach((list) => {
      if (list.languageCode === homePageState?.currentLanguage)
        language = list.name;
    });
    return language;
  };
  return (
    <div className="custom-language-menu-title sidebar">
      {displayLanguage()}
      <img src={arrowIcon} className="arrow-icon" alt="arrow" />
    </div>
  );
};

export default RenderLanguageLabel;
