import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  ButtonType,
  AntdConfigProvider,
  Divider,
} from "syngenta-digital-cropwise-react-ui-kit";

import ProductTemplateApi from "../../core/api/productTemplateApi";
import type { ApplicationState } from "../../core/redux/reducers/";
import {
  setBannerDetails,
  setAnchorId,
} from "../../core/redux/reducers/productTemplateSlice";
import ConstantKey from "../../core/locale/strings.json";
import { setRecommendationList } from "../../core/redux/reducers/recommendationsSlice";
import { BannerType } from "../../core/types/banners.dto";

import Banners from "../../shared/components/Banners";
import GetNow from "../../shared/layout/sidebar/getNow";
import { logSideBarMenuClickAmplitudeEvent } from "../../shared/layout/sidebar/utils";

import MoreSyngentaTiles from "../moresyngentatiles";
import LoyaltyPoints from "../loyalty-points/loyaltyPoints";
import ForeCastHome from "../forecastHome";
import InsightsWidget from "./insightsWidget";
import SprayClock from "./SprayClock";

import "./dashboard.less";

export const AppDashboard = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const ProductTemplate = useSelector(
    (state: ApplicationState) => state.productTemplateDetails
  );

  const breakpoint = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (width < breakpoint) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);
  useEffect(() => {
    if (logSideBarMenuClickAmplitudeEvent.dashboard) {
      logSideBarMenuClickAmplitudeEvent.dashboard();
    }
    if (location?.state?.anchorId) {
      dispatch(setAnchorId(location?.state?.anchorId));
    }
  }, []);
  useEffect(() => {
    if (ProductTemplate?.anchorId && ProductTemplate?.bannerDetails) {
      window.location.replace(ProductTemplate?.anchorId);
    }
  }, [ProductTemplate?.bannerDetails, ProductTemplate?.anchorId]);

  useEffect(() => {
    if (ProfileDataSelector?.defaultLocation?.countryCode) {
      const countryCode =
        ProfileDataSelector.defaultLocation?.countryCode?.toUpperCase();
      let newOffers: any = [];
      let alertsRecommendation: any = [];
      let newProduct: any = [];
      let claimedAndCommittedOffers: any = [];
      ProductTemplateApi.getBannerTemplateApi(countryCode)
        .then((res) => {
          res.data?.content?.forEach((banner: any) => {
            if (
              banner.bannerCategory === "ALERT" ||
              banner.bannerCategory === "RECOMMENDATION" ||
              banner.bannerCategory === "REPORT"
            )
              alertsRecommendation.push(banner);
            else if (banner.bannerCategory === "NEW_OFFER") {
              if (
                banner.offerStatus === "CLAIMED" ||
                banner.offerStatus === "COMMITTED"
              )
                claimedAndCommittedOffers.push(banner);
              else newOffers.push(banner);
            } else if (
              banner.bannerCategory === "NEW_PRODUCT" ||
              banner.bannerCategory === "SERVICES"
            )
              newProduct.push(banner);
          });
          newOffers = newOffers?.sort((a: any, b: any) => {
            if (a.weight < b.weight) return 1;
            else if (b.weight < a.weight) return -1;
            return 0;
          });
          alertsRecommendation = alertsRecommendation?.sort(
            (a: any, b: any) => {
              if (a.weight < b.weight) return 1;
              else if (b.weight < a.weight) return -1;
              return 0;
            }
          );
          newProduct = newProduct?.sort((a: any, b: any) => {
            if (a.weight < b.weight) return 1;
            else if (b.weight < a.weight) return -1;
            return 0;
          });
          claimedAndCommittedOffers = claimedAndCommittedOffers?.sort(
            (a: any, b: any) => {
              if (a.weight < b.weight) return 1;
              else if (b.weight < a.weight) return -1;
              return 0;
            }
          );

          const banners = {
            newOffers,
            alertsRecommendation,
            newProduct,
            claimedAndCommittedOffers,
          };
          dispatch(setBannerDetails(banners));
        })
        .catch(() => {});
    }
  }, [ProfileDataSelector?.profileData]);
  useEffect(() => {
    if (ProfileDataSelector?.profileData?.email)
      ProductTemplateApi.getUserRecommendations(
        ProfileDataSelector?.profileData?.email,
        0,
        20
      )
        .then((response) => {
          if (response?.data) {
            dispatch(setRecommendationList(response?.data));
          }
        })
        .catch(() => {});
  }, [ProductTemplate?.bannerDetails]);

  return (
    <AntdConfigProvider>
      {ProfileDataSelector?.dashboardLoader && (
        <>
          <div className="mf-loader-overlay"></div>
          <div className="mf-loader"></div>
        </>
      )}
      <div className="mf-grid-story-container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={15} xl={15}>
            <div className="mapview-container">
              <div className="welcome-title-text">{`${translate(
                ConstantKey.WELCOME
              )},  ${
                ProfileDataSelector?.profileData.firstName
                  ? ProfileDataSelector?.profileData.firstName
                  : "NA"
              }`}</div>
              <div className="sub-tile-text">
                {translate(ConstantKey.WELCOME_MSG)}
              </div>
            </div>
            {isMobile && (
              <div className="mf-mt-10">
                <GetNow />
              </div>
            )}
            {isMobile && (
              <div className="mf-mt-10">
                {" "}
                <LoyaltyPoints />
              </div>
            )}
            <div className="insights-sprayclock-containter">
              <InsightsWidget />
              <SprayClock />
            </div>
            <Divider style={{ width: "100%" }} />
            {ProductTemplate?.bannerDetails?.newOffers?.length !== 0 && (
              <div id="myoffers">
                <Banners
                  title={translate(ConstantKey.MY_OFFERS)}
                  titleIcon="myOfferIcon"
                  buttonType={ButtonType.outline}
                  bannerType={BannerType.backgroundColor}
                  data={
                    ProductTemplate?.bannerDetails?.newOffers
                      ? ProductTemplate?.bannerDetails?.newOffers
                      : []
                  }
                />
              </div>
            )}
            {ProductTemplate?.bannerDetails?.claimedAndCommittedOffers
              ?.length !== 0 && (
              <Banners
                title={translate(ConstantKey.COMMITTED_AND_CLAIMED_OFFERS)}
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.claimedAndCommittedOffers
                    ? ProductTemplate?.bannerDetails?.claimedAndCommittedOffers
                    : []
                }
              />
            )}
            {ProductTemplate?.bannerDetails?.alertsRecommendation?.length !==
              0 && (
              <Banners
                title={translate(ConstantKey.MY_RECOMMEDATIONS)}
                titleIcon="myRecommendationsIcon"
                buttonType={ButtonType.outline}
                bannerType={BannerType.backgroundImage}
                data={
                  ProductTemplate?.bannerDetails?.alertsRecommendation
                    ? ProductTemplate?.bannerDetails?.alertsRecommendation
                    : []
                }
              />
            )}
            <div id="productsandservice">
              {ProductTemplate?.bannerDetails?.newProduct?.length !== 0 && (
                <Banners
                  title={translate(ConstantKey.NEW_PRODUCTS_AND_SERVICES)}
                  titleIcon="newProductServicesIcon"
                  buttonType={ButtonType.outline}
                  bannerType={BannerType.backgroundImage}
                  data={
                    ProductTemplate?.bannerDetails?.newProduct
                      ? ProductTemplate?.bannerDetails?.newProduct
                      : []
                  }
                />
              )}
            </div>
            <MoreSyngentaTiles />
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            {!isMobile && <LoyaltyPoints />}
            <ForeCastHome />
          </Col>
        </Row>
      </div>
    </AntdConfigProvider>
  );
};

export default AppDashboard;
