import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import type { AppRoutesPropsDto, RoutesDto } from "../core/types/routes.dto";
import { isAccessTokenAvailable } from "../core/utils/common-function";

import AppLayout from "../shared/layout/app-layout";

import PageNotFound from "../pages/error-handler/pageNotFound";

const getUrl = (userData: any): string => {
  let url = "";
  if (!userData.isValidUser) url = "/signin";
  else if (userData.defaultLocation) {
    url = "/dashboard";
  } else url = "/drop-a-pin";
  return url;
};

const ProtectedRouteMiddleware = (): JSX.Element => {
  const location = useLocation();
  const profileDataSelector = useSelector((state: any) => state.accountDetials);
  if (!profileDataSelector.isValidUser) {
    localStorage.setItem("returnUrl", location.pathname);
  }
  // if the user is not logged in or doesn't have valid access-token we are clearing the localstorage, redux state and redirected to /.
  return <Navigate to="/" state={{ locationFrom: location.pathname }} />;
};

const AuthRouteMiddleware = (): JSX.Element => {
  // if the user is already signin and try to access the auth routes we are redirecting to the dasboard page.
  const location = useLocation();
  const profileDataSelector = useSelector((state: any) => state.accountDetials);
  const returnUrl =
    localStorage.getItem("returnUrl") ?? getUrl(profileDataSelector);
  localStorage.removeItem("returnUrl");
  return (
    <Navigate to={returnUrl} state={{ locationFrom: location.pathname }} />
  );
};

const BaseRouteMiddleware = (): JSX.Element => {
  // if the user is not logged in or doesn't have valid access-token we are clearing the localstorage, redux state and redirected to /.
  const location = useLocation();
  const profileDataSelector = useSelector((state: any) => state.accountDetials);

  const toUrl = getUrl(profileDataSelector);
  return <Navigate to={toUrl} state={{ locationFrom: location.pathname }} />;
};

export const AppRoutes = ({ routes }: AppRoutesPropsDto): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        {/* OpenRoutes */}
        {routes.openRoutes.map((routeInfo: RoutesDto) => (
          <Route
            key={routeInfo.path}
            path={routeInfo.path}
            element={routeInfo.element}
          />
        ))}
        {/* AuthRoutes */}
        {routes.authRoutes.map((routeInfo: RoutesDto) => (
          <Route
            key={routeInfo.path}
            path={routeInfo.path}
            element={
              !isAccessTokenAvailable() ? (
                routeInfo.element
              ) : (
                <AuthRouteMiddleware />
              )
            }
          />
        ))}
        {/* ProtectedRoutes */}
        {routes.protectedRoutes.map((routeInfo: RoutesDto) => (
          <Route
            key={routeInfo.path}
            path={routeInfo.path}
            element={
              isAccessTokenAvailable() ? (
                routeInfo.element
              ) : (
                <ProtectedRouteMiddleware />
              )
            }
          />
        ))}
        {/* Redirecting base url '/' to '/dashboard' or '/splash' */}
        <Route path="/" element={<BaseRouteMiddleware />}></Route>
        {/* page not found - Redirecting to '/dashboard' or '/splash' */}
        <Route path="*" element={<PageNotFound />}></Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
