import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";

import ConstantKey from "../../core/locale/strings.json";

import AuthFooter from "../../shared/components/authFooter/AuthFooter";

import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import forgotPasswordSuccess from "../../assets/images/forgot-password-success.svg";

import "./forgot-password.less";

const ForgotPasswordSuccess = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const onClickOk = (): void => {
    navigate("/signin");
  };
  return (
    <div
      className="forgot-newpassword-success-container"
      data-testid="forgot-new-password-container"
    >
      <div className="forgot-newpassword-success-content">
        <div className="flex-grow-0">
          <img
            className="forgot-password-field-logo flex-grow-0"
            src={synMyFieldLogo}
            alt="Syngenta Myfield Logo"
          />
        </div>
        <div className="forgot-password-form flex-grow-0">
          <img
            alt="pasword success"
            src={forgotPasswordSuccess}
            className="mf-mb-10 forgot-password-image-style"
          />
          <p className="forgot-password-desc">
            {translate(ConstantKey.CHECK_YOUR_EMAIL)}
          </p>
          <p className="forgot-password-success-msg mf-mb-0">
            {translate(ConstantKey.FORGOT_PASSWORD_CHECK_EMAIL_MSG)}
          </p>
          <Button
            type={ButtonType.primary}
            key="okBtn"
            className="forgot-password-button mf-width-100"
            onClick={onClickOk}
          >
            {translate(ConstantKey.OK)}
          </Button>
        </div>

        <AuthFooter />
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
