import requests from "./axios";

const ForeCastApi = {
  getForecastWeeklyData: async (
    lat: number,
    lon: number,
    countrycode: string,
    languageCode: string
  ): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/forecast/weekly/?latitude=${lat}&longitude=${lon}&countryCode=${countrycode}&languageCode=${languageCode}`
    ),
  getForecastHourlyTodayData: async (
    lat: number,
    lon: number,
    countrycode: string,
    languageCode: string
  ): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/forecast/hourly/?latitude=${lat}&longitude=${lon}&countryCode=${countrycode}&languageCode=${languageCode}`
    ),
  getTodayAllHoursForecastData: async (
    lat: number,
    lon: number,
    countrycode: string
  ): Promise<any> =>
    await requests.get(
      `${process.env.REACT_APP_MYFIELD_API_URL}/v1/forecast/daily/?latitude=${lat}&longitude=${lon}&countryCode=${countrycode}`
    ),
};
export default ForeCastApi;
