import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  AntdConfigProvider,
  ButtonType,
} from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  type BannerDataDTO,
  type BannersDTO,
} from "../../../core/types/banners.dto";

import type { ApplicationState } from "../../../core/redux/reducers";
import ConstantKey from "../../../core/locale/strings.json";
import { sendAmplitudeData } from "../../../core/utils/analytics";
import { bannerCategoryDetails } from "../../../core/utils/analytics/constants";

import newProductServicesIcon from "../../../assets/icons/productsandservices_icon.svg";
import myOfferIcon from "../../../assets/icons/myoffers_icon.svg";
import myRecommendationsIcon from "../../../assets/icons/recommendation_icon.svg";

import "./banners.less";

const bannerTitleIcons: any = {
  newProductServicesIcon,
  myOfferIcon,
  myRecommendationsIcon,
};

const Banners = (props: BannersDTO): JSX.Element => {
  const { t: translate } = useTranslation();
  const RecommendationsDetails = useSelector(
    (state: ApplicationState) => state.recommendationsDetails
  );
  const history = useNavigate();

  const handleViewMoreBtnClick = useCallback((ele: BannerDataDTO): void => {
    const details = {
      name: ele?.name,
      bannerCategory: ele?.bannerCategory,
      offerStatus: ele?.offerStatus,
    };

    sendAmplitudeData(bannerCategoryDetails, details);
    if (ele.bannerCategory === "RECOMMENDATION") {
      if (
        RecommendationsDetails?.recommendationList?.totalRecommedationIdsCount >
        0
      )
        history("/myrecommendations");
      else window.open(ele.redirectUrl);
    } else if (ele?.redirectUrl?.includes("internal")) {
      const url = ele.redirectUrl.replace("internal", "");
      history(url);
    } else if (ele?.redirectUrl) window.open(ele.redirectUrl);
  }, []);

  return (
    <AntdConfigProvider>
      <div>
        <div className="banner-title mf-mt-15 mf-pb-10">
          {props?.titleIcon && (
            <span>
              <img
                src={bannerTitleIcons[props.titleIcon]}
                data-testid="card-box-img"
                alt="bannerImages"
                className="banner-title-icons mf-pr-5"
              />
            </span>
          )}
          <span>{props.title}</span>
        </div>
        <Row align="top" justify="start" gutter={[16, 0]}>
          {props.data?.map((ele: BannerDataDTO, i: number) => {
            return (
              <Col
                lg={12}
                md={12}
                sm={24}
                key={ele.bannerRefId}
                className="banner-column"
              >
                <div className="banner-container mf-mb-20">
                  <div className="banner-image">
                    <img
                      src={ele.imageRefId}
                      data-testid="card-box-img"
                      alt="bannerImages"
                    />
                  </div>
                  <div className="banner-info-sec">
                    {ele?.name && (
                      <p className="banner-info-title">{ele.name}</p>
                    )}
                    {ele?.redirectUrl && (
                      <Button
                        onClick={() => {
                          handleViewMoreBtnClick(ele);
                        }}
                        className="banner-info-btn"
                        type={ButtonType.primary}
                      >
                        {translate(ConstantKey.VIEW_MORE)}
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </AntdConfigProvider>
  );
};
export default Banners;
