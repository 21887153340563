import { useEffect, useState, type FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DownloadOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Layout,
  Select,
  Sidebar,
  Tooltip,
} from "syngenta-digital-cropwise-react-ui-kit";
import ConstantKey from "../../core/locale/strings.json";
import arrowIcon from "../../assets/icons/Iconarrow.svg";
import LegalDocsApi from "../../core/api/legalDocsApi";
import CountryApi from "../../core/api/countryApi";
import { setCountriesData } from "../../core/redux/reducers/accountProfileSlice";
import {
  setCurrentLanguage,
  setFromPars,
} from "../../core/redux/reducers/homePageSlice";
import type { ApplicationState } from "../../core/redux/reducers";
import type {
  CountryOptions,
  SearchBarType,
} from "../../core/types/accountProfile.dto";
import { formatDate, titleCase } from "../../core/utils/common-function";
import LegalDocsSidebar from "./legal-docs-sidebar";
import "./legal-docs.less";

const SearchBar: FC<SearchBarType> = ({
  countryList,
  selectedCountry,
  handleCountryChange,
}: SearchBarType) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <div className="select-country">
        <span>{translate(ConstantKey.LOCATION_OF_RESIDENCE)}</span>
        <Tooltip
          placement="top"
          title={translate(ConstantKey.LOCATION_OF_RESIDENCE_TOOLTIP)}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <div className="search-country-wrapper">
        <Select
          showSearch
          data-testid="legal-docs-searchbar"
          value={selectedCountry}
          style={{ width: "100%" }}
          placeholder={translate(ConstantKey.SEARCH_SELECT_FIELD)}
          optionFilterProp="children"
          filterOption={(input, option) =>
            String(option?.label ?? "")
              .toLowerCase()
              .includes(String(input.toLowerCase()))
          }
          filterSort={(optionA, optionB) =>
            String(optionA?.label ?? "")
              .toLowerCase()
              .localeCompare(String(optionB?.label ?? "").toLowerCase())
          }
          onChange={handleCountryChange}
          options={countryList}
        />
        <SearchOutlined className="country-search-icon" />
      </div>
    </>
  );
};

export const LegalDocuments = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const profileData = useSelector(
    (state: ApplicationState) => state.accountDetials
  );
  const homePageState = useSelector(
    (state: ApplicationState) => state.homepage
  );

  const [collapsedMenu, setCollapsedMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedTab, setSelectedTab] = useState("TC");
  const [countryList, setCountryList] = useState<CountryOptions[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [queryParams, setQueryParams] = useSearchParams();
  const [currentDocument, setCurrentDocument] = useState<string>("");
  const [documentCreatedDate, setDocumentCreatedDate] = useState<string>("");
  const [isFetchingLegalDocs, setIsFetchingLegalDocs] =
    useState<boolean>(false);
  const breakpoint = 992;

  const currentLanguage = localStorage.getItem("userLanguage");

  const getDocType: Record<string, string> = {
    PN: "PRIVACY_NOTICE",
    TC: "TERMS_AND_CONDITION",
  };

  const getCountryName: Record<string, string> = {
    PL: "Poland",
    HU: "HUNGARY",
    RO: "ROMANIA",
    EN: "UNITEDSTATES",
  };

  const languageLabels: Record<string, string> = {
    en: ConstantKey.ENGLISH,
  };
  useEffect(() => {
    const country = queryParams.get("country") as string;
    const tab = queryParams.get("tab") as string;
    if (country && tab)
      dispatch(setFromPars({ countryFromParams: country, tabFromParams: tab }));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
    if (currentLanguage) {
      dispatch(setCurrentLanguage(currentLanguage));
    }
    if (profileData?.countriesData)
      CountryApi.getCountryList()
        .then((response: any) => {
          if (response?.data?.content) {
            dispatch(setCountriesData(response.data.content));
          }
        })
        .catch(() => {});
  }, []);

  useEffect(() => {
    if (homePageState?.countryFromParams)
      handleCountryChange(homePageState?.countryFromParams);
  }, [homePageState]);

  useEffect(() => {
    if (width < breakpoint) {
      setCollapsedMenu(true);
    } else {
      setCollapsedMenu(false);
    }
  }, [width]);

  useEffect(() => {
    if (profileData?.countriesData) {
      const mappedCountryList: CountryOptions[] = [];

      profileData?.countriesData?.forEach((country) => {
        mappedCountryList.push({
          label: titleCase(country.name),
          value: country.isoCode,
        });
      });
      setCountryList(mappedCountryList);
    }
  }, [profileData?.countriesData]);

  const fetchLegalDocs = (
    countryFromParams: string,
    docTypePayload: string
  ): any => {
    setIsFetchingLegalDocs(true);
    LegalDocsApi.getLegalDocs(countryFromParams, docTypePayload)
      .then((res: any) => {
        const response = res.data;
        setIsFetchingLegalDocs(false);
        if (response?.updatedOn) {
          const formattedDate = formatDate(response.updatedOn);
          setDocumentCreatedDate(formattedDate);
        } else {
          setDocumentCreatedDate("");
        }
        if (response?.contentUrl) {
          setCurrentDocument(response.contentUrl);
        } else {
          setCurrentDocument("");
        }
      })
      .catch(() => {
        setIsFetchingLegalDocs(false);
        setCurrentDocument("");
      });
  };
  const handleCountryChange = (value: string): void => {
    if (value) {
      setSelectedCountry(value);
      let docTypePayload = "";
      if (homePageState?.tabFromParams) {
        setSelectedTab(homePageState?.tabFromParams);
        docTypePayload = getDocType[homePageState?.tabFromParams];
      } else {
        setSelectedTab("TC");
        docTypePayload = getDocType.TC;
      }
      setQueryParams({ country: value, tab: docTypePayload });
      if (homePageState?.countryFromParams !== value)
        dispatch(
          setFromPars({
            countryFromParams: value,
            tabFromParams: homePageState?.tabFromParams,
          })
        );
      fetchLegalDocs(value, docTypePayload);
    }
  };

  const renderLanguageLabel = (): JSX.Element => {
    return (
      <div className="custom-language-menu-title sidebar">
        {languageLabels[homePageState?.currentLanguage as string]}
        <img src={arrowIcon} className="arrow-icon" alt="arrow" />
      </div>
    );
  };

  const handleTabChange = (value: string): void => {
    if (value === "PN") {
      setSelectedTab(value);
      if (homePageState?.countryFromParams)
        setQueryParams({
          country: homePageState?.countryFromParams,
          tab: "PN",
        });
    } else {
      setSelectedTab(value);
      if (homePageState?.countryFromParams)
        setQueryParams({
          country: homePageState?.countryFromParams,
          tab: "TC",
        });
    }
    let docTypePayload = "";
    if (value) {
      docTypePayload = getDocType[value];
    } else {
      docTypePayload = getDocType.TC;
    }
    if (homePageState?.countryFromParams && docTypePayload)
      fetchLegalDocs(homePageState.countryFromParams, docTypePayload);
  };

  const renderLegalDocument = (): JSX.Element => {
    return currentDocument ? (
      <iframe
        title="legal-documents"
        height="500px"
        width="100%"
        src={`${currentDocument}#toolbar=0`}
      ></iframe>
    ) : (
      <div className="legal-docs-no-content">
        <p>{translate(ConstantKey.LEGAL_DOCS_CONTENT_NOT_AVAILABLE)}</p>
      </div>
    );
  };

  return (
    <Layout className="legal-docs-container">
      <Layout>
        <Sidebar
          expandedIcon={
            <LegalDocsSidebar
              collapsedMenu={collapsedMenu}
              setCollapsedMenu={setCollapsedMenu}
              selectedTab={selectedTab}
              countryList={countryList}
              SearchBar={SearchBar}
              selectedCountry={selectedCountry}
              handleCountryChange={handleCountryChange}
              handleTabChange={handleTabChange}
              renderLanguageLabel={renderLanguageLabel}
            />
          }
          collapsedMenu={collapsedMenu}
          theme="light"
          className="legal-docs-sidebar"
        />

        <Layout className="pdf-viewer-container">
          {collapsedMenu && (
            <>
              <p className="legal-documents-title mobile">
                {translate(ConstantKey.LEGAL_DOCUMENTS)}
              </p>
              <div className="legal-documents-search mobile">
                <SearchBar
                  countryList={countryList}
                  selectedCountry={selectedCountry}
                  handleCountryChange={handleCountryChange}
                />
              </div>
            </>
          )}
          <div className="pdf-viewer-box">
            <div className="pdf-viewer-header">
              <div className="pdf-viewer-detail">
                <span className="pdf-viewer-title">
                  {selectedTab === "TC"
                    ? translate(ConstantKey.TERMS_AND_CONDITIONS)
                    : translate(ConstantKey.PRIVACY_NOTICE)}
                </span>
                <span className="pdf-viewer-name">
                  {getCountryName?.[selectedCountry]} •{" "}
                  {documentCreatedDate ?? ""}
                </span>
              </div>
              <div className="download-btn-wrapper">
                <a href={currentDocument} download="example.pdf">
                  <Button className="pdf-download-btn">
                    <DownloadOutlined />
                    {translate(ConstantKey.DOWNLOAD)}
                  </Button>
                </a>
              </div>
            </div>
            {isFetchingLegalDocs ? (
              <div className="legal-docs-loader-wrapper">
                <div className="mf-loader"></div>
              </div>
            ) : (
              renderLegalDocument()
            )}
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LegalDocuments;
