import { useTranslation } from "react-i18next";
import moment from "moment";

import ConstantKey from "../../../core/locale/strings.json";

import "./globalfooter.less";

const GlobalFooter = (): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <footer className="footerblock">
      <div>{translate(ConstantKey.FOOTER_MSG_TEXT)} </div>{" "}
      <div>
        &#169;
        {`${moment().format("YYYY")} ${translate(
          ConstantKey.SYNGENTA_COPYRIGHT
        )}`}
      </div>
    </footer>
  );
};

export default GlobalFooter;
