import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";

import ConstantKey from "../../core/locale/strings.json";

import AuthFooter from "../../shared/components/authFooter/AuthFooter";

import forgotNewPasswordImage from "../../assets/images/forgot-newpassword-success.svg";
import synMyFieldLogo from "../../assets/images/synMyFieldLogo.svg";
import accountCircle from "../../assets/icons/account_circle_white.svg";

import "./forgot-password.less";

const NewPasswordSuccess = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const onClickEnterMyField = (): void => {
    navigate("/signin");
  };
  return (
    <div className="forgot-newpassword-success-container">
      <div className="forgot-newpassword-success-content">
        <img
          src={synMyFieldLogo}
          className="forgot-password-field-logo"
          alt="myfieldlogo"
        />
        <div className="mf-mt-15">
          <img
            src={forgotNewPasswordImage}
            className="forgot-password-image-style"
            alt="emailimage"
          />
        </div>
        <h2 className="forgot-password-desc mf-mt-50">
          {translate(ConstantKey.YOUR_NEW_PASSWORD_HAS_BEEN_CREATED)}
        </h2>
        <p className="forgot-password-success-msg">
          {translate(ConstantKey.YOUR_NEW_PASSWORD_CRATED_MSG)}{" "}
        </p>
        <Button
          type={ButtonType.primary}
          onClick={onClickEnterMyField}
          className="email-success-enter-button mf-mt-15"
        >
          <img src={accountCircle} className="mf-mr-10" alt="account circle" />
          {translate(ConstantKey.ENTER_SYNGENTA_PORTAL)}
        </Button>
        <AuthFooter />
      </div>
    </div>
  );
};
export default NewPasswordSuccess;
