import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { notification as Notification } from "antd";
import {
  Divider,
  Space,
  Button,
  ButtonType,
  notification,
} from "syngenta-digital-cropwise-react-ui-kit";
import { DeckOfApps } from "@cw-elements/deck-of-apps";
import syngentaLogo from "../../../assets/icons/syngenta-logo-provisorio.svg";
import myfieldTitle from "../../../assets/icons/myfield_title.svg";
import type { ApplicationState } from "../../../core/redux/reducers";
import GrowerApi from "../../../core/api/growerAPI";
import ConstantKey from "../../../core/locale/strings.json";
const MyFieldLogo = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translate } = useTranslation();

  const profileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  useEffect(() => {
    if (
      profileDataSelector?.profileData?.emailVerified === false &&
      location.pathname === "/dashboard"
    ) {
      Notification.destroy();
      showVerifiPopup() &&
        Notification.open({
          className: "mf-zindex-height",
          closeIcon: null,
          btn: (
            <div>
              <Button
                data-testid="click-here-manage-field"
                className="mf-mr-10"
                onClick={handleGotIt}
                type={ButtonType.outline}
              >
                {translate(ConstantKey.GOT_IT)}
              </Button>
              <Button
                data-testid="click-here-manage-field"
                className=""
                onClick={handleResendEmail}
                type={ButtonType.primary}
              >
                {translate(ConstantKey.RESEND_EMAIL)}
              </Button>
            </div>
          ),
          onClick: () => {},
          message: (
            <p className="email-popup-title">
              {translate(ConstantKey.VERIFY_YOUR_EMAIL)}
            </p>
          ),
          description: translate(ConstantKey.EMAIL_VERIFY_POPUP_MSG),
          placement: "topRight",
          duration: null,
        });
    }
  }, [profileDataSelector?.profileData]);
  const showVerifiPopup = (): boolean => {
    let isShow = true;
    const msInHour = 1000 * 60 * 60;
    const startDate = localStorage.getItem("email-verification-gotit");
    if (startDate) {
      const endDate = new Date();
      const houts =
        (endDate.getTime() - new Date(startDate).getTime()) / msInHour;
      if (
        profileDataSelector?.profileData?.emailVerificationInterval &&
        houts < profileDataSelector?.profileData?.emailVerificationInterval
      )
        isShow = false;
    }
    return isShow;
  };
  const handleGotIt = (): void => {
    Notification.destroy();
    localStorage.setItem("email-verification-gotit", `${new Date()}`);
  };
  const handleResendEmail = (): void => {
    if (profileDataSelector?.profileData?.email)
      GrowerApi.reSendEmail(profileDataSelector?.profileData?.email)
        .then((res: any) => {
          Notification.destroy();
          if (res?.status === 200) {
            localStorage.setItem("email-verification-gotit", `${new Date()}`);
            notification.success({
              message: res?.data?.message,
              description: "",
              placement: "topRight",
            });
          } else if (res?.status === 403)
            notification.success({
              message: res?.data?.errorMessage,
              description: "",
              placement: "topRight",
            });
        })
        .catch((error: any) => {
          notification.error({
            message: error?.cause?.data?.errorMessage,
            description: "",
            placement: "topRight",
          });
        });
  };
  return (
    <Space
      align="start"
      className="mf-logo-sec"
      direction="horizontal"
      size={0}
    >
      <DeckOfApps
        onLogout={() => {}}
        headerSelector="dockappoffstyle"
        orgId={profileDataSelector?.defaultOrgId ?? ""}
        propertyId={profileDataSelector?.defaultPropertyId ?? ""}
        workspaceId={
          profileDataSelector?.profileData?.default_workspace_id ?? ""
        }
      />

      <img src={syngentaLogo} className="appLogo mf-mb-10" alt="logoIcon" />
      <Divider type="vertical" className="mf-mb-5 app-logo-divider" />
      <button
        onClick={() => {
          navigate("/dashboard");
        }}
        className="mf-btn-img mf-height-100 mf-cursor-pointer"
      >
        <img
          src={myfieldTitle}
          className="app-logo-title mf-mb-10"
          alt="logoIcon"
        />
      </button>
    </Space>
  );
};

export default MyFieldLogo;
