import { useTranslation } from "react-i18next";

import ConstantKey from "../../../core/locale/strings.json";

import "./authWelcomeMessage.less";

export const AuthWelcomeMessage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  return (
    <div className="auth-welcome-message-container">
      <p className="auth-welcome-title">
        {translate(ConstantKey.WELCOME_MY_FIELD)}
      </p>
      <p className="auth-welcome-desc">
        {translate(ConstantKey.SMART_PLATFORM_TO_ENHANCE_FIELD)}
      </p>
    </div>
  );
};

export default AuthWelcomeMessage;
