export const INITIAL_PROFILE_DATA = {
  authorities: [],
  auto_created_org_id: "",
  clock_format: null,
  country_code: "",
  created_at: "",
  date_format: null,
  default_licensing_account_id: "",
  default_workspace_id: "",
  deleted: false,
  email: "",
  email_verified: false,
  first_name: "",
  id: "",
  identity_provider: "",
  is_using_rbac: false,
  job_title: null,
  last_name: "",
  locale: "",
  login: "",
  name: "",
  opt_ins: [],
  pending_change_password: false,
  phone: null,
  photo_uri: "",
  role: null,
  roleType: "",
  ssn: null,
  tutorial_complete: false,
  type: "",
  updated_at: "",
  userId: "",
  licensing_details: null,
};
