import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import GrowerApi from "../../../core/api/growerAPI";
import type { ApplicationState } from "../../../core/redux/reducers";
import {
  setNavigationMenu,
  setAppLinks,
} from "../../../core/redux/reducers/accountProfileSlice";
import { setAnchorId } from "../../../core/redux/reducers/productTemplateSlice";
import "./sidebar.less";

interface MenuItem {
  name: string;
  navigationUrl?: string;
  icon?: string;
  select?: boolean;
  submenus?: MenuItem[];
}

const getSelectedClass = (
  isSelected: boolean = false,
  baseClass: string = ""
): string => {
  return `${baseClass} ${
    isSelected ? "sidebar-selected-background" : ""
  }`.trim();
};

const SubMenuItem: React.FC<{
  menu: MenuItem;
  onItemClick: (url: string) => void;
}> = ({ menu, onItemClick }) => {
  const { t: translate } = useTranslation();
  return (
    <div
      className="collapse-submenu-item collapse-submenu-submenus"
      key={menu.name}
    >
      <div className={getSelectedClass(menu.select, "")}>
        {menu.select && (
          <span className="sidebar-collapse-selected-item"></span>
        )}
        <button
          className={getSelectedClass(
            menu?.select,
            "mf-btn-img collapse-menu-hover"
          )}
          onClick={() => {
            menu.navigationUrl && onItemClick(menu.navigationUrl);
          }}
        >
          {translate(menu.name)}
        </button>
      </div>
    </div>
  );
};

const MainMenuItem: React.FC<{
  submenu: MenuItem;
  onItemClick: (url: string) => void;
}> = ({ submenu, onItemClick }) => {
  const { t: translate } = useTranslation();

  const selectMenuInSubSetion = (submenus: MenuItem[]): string => {
    return submenus.some((menu) => menu.select)
      ? "sidebar-selected-background"
      : "";
  };

  return (
    <div
      className={getSelectedClass(submenu.select, "sidebar-icon-style")}
      key={submenu.name}
    >
      {submenu.select && (
        <span className="sidebar-collapse-selected-item"></span>
      )}
      <button
        data-testid={`testid-${submenu.name}`}
        className={getSelectedClass(
          submenu.select,
          `mf-btn-img ${
            submenu.submenus ? selectMenuInSubSetion(submenu.submenus) : ""
          }`
        )}
        onClick={() => {
          !submenu.submenus &&
            submenu.navigationUrl &&
            onItemClick(submenu.navigationUrl);
        }}
      >
        {submenu.icon && (
          <img src={submenu.icon} alt="icon" className="collapse-menu-margin" />
        )}
      </button>
      {submenu.submenus ? (
        submenu.submenus.map((menu) => (
          <SubMenuItem key={menu.name} menu={menu} onItemClick={onItemClick} />
        ))
      ) : (
        <div className="collapse-submenu-item popmenu">
          {translate(submenu.name)}
        </div>
      )}
    </div>
  );
};

const CollapseSidebar = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ProfileDataSelector = useSelector(
    (state: ApplicationState) => state.accountDetials
  );

  useEffect(() => {
    if (
      ProfileDataSelector?.defaultLocation?.countryCode &&
      !ProfileDataSelector?.navigationMenu
    ) {
      GrowerApi.getNavigationMenuItems(
        ProfileDataSelector?.defaultLocation?.countryCode
      )
        .then((response: any) => {
          if (response.status === 200) {
            const { sidebarMenu, iosLink, androidLink } = response.data ?? {};
            const menus = sidebarMenu?.map(processMenu) ?? [];
            dispatch(setNavigationMenu(menus));
            dispatch(setAppLinks({ iosLink, androidLink }));
          }
        })
        .catch(() => {});
    }
  }, [ProfileDataSelector?.defaultLocation]);

  useEffect(() => {
    ProfileDataSelector?.navigationMenu?.forEach((menu: MenuItem) => {
      if (menu.navigationUrl === window.location.pathname && !menu.select)
        updateMenuSelection(location.pathname);
    });
  }, [window.location.pathname, ProfileDataSelector?.navigationMenu]);

  const updateNavigationUrl = (menu: MenuItem): MenuItem => ({
    ...menu,
    select: false,
    navigationUrl: menu.navigationUrl?.replace("internal", "") ?? "",
  });

  const processMenu = (menu: MenuItem): MenuItem => {
    if (menu?.navigationUrl?.includes("internal"))
      return updateNavigationUrl(menu);
    if (menu?.submenus) {
      return { ...menu, submenus: menu.submenus.map(processMenu) };
    }
    return { ...menu, select: false };
  };

  const updateMenuSelection = useCallback(
    (path: string) => {
      if (!ProfileDataSelector?.navigationMenu) return;
      const updatedMenu = ProfileDataSelector.navigationMenu.map(
        (menu: MenuItem) => ({
          ...menu,
          select: menu.navigationUrl === path,
          submenus: menu.submenus?.map((sub) => ({
            ...sub,
            select: sub.navigationUrl === path,
          })),
        })
      );
      dispatch(setNavigationMenu(updatedMenu));
    },
    [ProfileDataSelector?.navigationMenu, dispatch]
  );

  const selectMenuItemOnClick = (path: string): void => {
    dispatch(setAnchorId(null));
    updateMenuSelection(path);
    if (path.startsWith("https")) {
      window.open(path, "_blank");
    } else if (path.includes("dashboard#")) {
      location.pathname === "/dashboard"
        ? window.location.replace(path)
        : navigate("/dashboard", { state: { anchorId: path } });
    } else {
      navigate(path);
    }
  };

  return (
    <div className="sidebar_collapse">
      {ProfileDataSelector?.navigationMenu?.map((submenu: MenuItem) => (
        <MainMenuItem
          key={submenu.name}
          submenu={submenu}
          onItemClick={selectMenuItemOnClick}
        />
      ))}
    </div>
  );
};

export default CollapseSidebar;
